import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { rem, flex, position, value, is } from 'styled-tidy'
import { Link } from 'gatsby'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import BalanceText from 'react-balance-text'
import Image from 'components/image'
import Button from 'components/button'

const { bone } = theme.colors

const Graphic = styled.div`
  ${flex('column', 'center', 'center')}
  background: ${bone};
  overflow: hidden;
  position: relative;

  :before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .gatsby-image-wrapper {
    ${position('absolute !important', 0)};
    transition: all 25ms ease;
  }

  ${media.small`
    :before {
      padding-bottom: 75%;
    }
  `}

  ${is('inView')`
    @keyframes projectTileGraphicIn {
      0% {
        transform: scale(1.5);
      }
      100% {
        transform: none;
      }
    }
    .gatsby-image-wrapper {
      animation: projectTileGraphicIn 500ms forwards ease;
    }
  `}
`

const Detail = styled.p`
  ${flex('column', 'center', 'center')}
  background: ${value('bg')};
  overflow: hidden;
  position: relative;

  h2 {
    color: ${value('color')};
    font-size: 4vw;
    line-height: 1.25;
    padding: 0 3vw;
  }

  p {
    color: ${value('color')};
    display: none;
    font-size: ${rem(18)};
    line-height: 1.25;
    padding: 0 3vw;
  }

  a {
    font-size: ${rem(12)};
    height: ${rem(32)};
    margin-top: ${rem(16)};
    text-transform: uppercase;
  }

  ${media.xsmall`
    h2,
    p {
      padding: 0 ${rem(48)};
    }
    p {
      font-size: ${rem(16)};
    }
  `}

  ${media.medium`
    h2 {
      font-size: ${rem(32)};
    }
    p {
      display: block;
    }
    a {
      font-size: ${rem(14)};
      height: ${rem(40)};
    }
  `}

  ${media.xlarge`
    h2,
    p {
      padding: 0 ${rem(16)};
    }
  `}

  ${is('inView')`
    @keyframes projectTileTitleIn {
      0% {
        filter: blur(${rem(48)});
        transform: scale(1.5);
      }
      100% {
        filter: none;
        transform: none;
      }
    }
    h2 {
      animation: projectTileTitleIn 1500ms forwards ease;
    }

    @keyframes projectTileDescriptionIn {
      0% {
        filter: blur(${rem(48)});
        transform: scale(0.1, 0.5);
      }
      100% {
        filter: none;
        transform: none;
      }
    }
    p {
      animation: projectTileDescriptionIn 1500ms forwards ease;
    }

    @keyframes projectTileButtonIn {
      0% {
        filter: blur(${rem(48)});
        opacity: 0;
      }
      100% {
        filter: none;
        opacity: 1;
      }
    }
    a {
      animation: projectTileButtonIn 1500ms forwards ease;
    }
  `}
`

export default ({ project }) => {
  const { name, path, images, tile, description } = project
  const [ref, inView] = useInView()

  return (
    <div ref={ref}>
      <Graphic inView={inView}>
        <Image file={images[0]} />
      </Graphic>
      <Detail inView={inView} bg={tile.bg} color={tile.color}>
        <h2>{name}</h2>
        <p>
          <BalanceText>{description}</BalanceText>
        </p>
        <Button as={Link} kind="outline" outlineColor={tile.color} to={path}>
          View Project
        </Button>
      </Detail>
    </div>
  )
}
