import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 48, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="50 60 246 256"
    fill={fill}
    {...rest}
  >
    <g transform="rotate(180 173 188)">
      <path d="M299.89 6.968h-.377v.987c.084.02.23.04.44.04.504 0 .735-.21.735-.523 0-.357-.357-.504-.798-.504m.944-.251v.043c.419.125.712.418.712.796 0 .336-.146.609-.334.754-.253.147-.545.253-1.195.253-.567 0-1.008-.042-1.321-.106V5.019h.797v1.385h.377c.44 0 .65-.168.712-.545.106-.4.169-.714.273-.84h.86c-.085.126-.146.336-.252.86-.104.46-.294.712-.63.838m-8.701 201.269c-.653.525-6.753 5.126-19.61 5.126-3.214 0-6.606-.292-10.13-.871-2.485 17.057-16.563 25.365-17.217 25.742l-3.457 1.99-2.275-3.275c-.17-.244-4.172-6.068-6.135-14.356-2.305-9.73-.905-18.87 4.04-26.691-5.972-3.323-15.506-4.191-17.487-4.215H61.266c-4.138 0-7.52-3.362-7.536-7.494-.061-14.587 2.343-28.682 6.952-40.761 5.466-14.325 13.597-24.872 24.166-31.346 11.85-7.26 31.144-11.422 52.934-11.422 10.196 0 20.357.92 29.385 2.66 14.229 2.745 27.146 7.434 38.39 13.937a105.156 105.156 0 0126.179 21.437c12.57 14.214 20.054 30.088 25.62 44.138a53.641 53.641 0 012.218-.046c13.759 0 22.216 5.499 26.889 10.113 5.067 5.003 6.87 9.993 7.059 10.545l.984 2.882z" />
      <path d="M75.966 196.074h21.251a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.25a1.86 1.86 0 01-1.86-1.86v-18.934a1.86 1.86 0 011.86-1.86m29.284 0h21.25a1.86 1.86 0 011.861 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.25a1.86 1.86 0 01-1.86-1.86v-18.934a1.86 1.86 0 011.86-1.86m29.738 0h21.252a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86H134.99a1.86 1.86 0 01-1.86-1.86v-18.934a1.86 1.86 0 011.86-1.86m29.383 0h21.251a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.251a1.86 1.86 0 01-1.86-1.86v-18.934a1.86 1.86 0 011.86-1.86m-59.122 27.242h21.25a1.86 1.86 0 011.861 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.25a1.86 1.86 0 01-1.86-1.86v-18.933a1.86 1.86 0 011.86-1.861m29.738 0h21.252a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86H134.99a1.86 1.86 0 01-1.86-1.86v-18.933a1.86 1.86 0 011.86-1.861m29.383 0h21.251a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.251a1.86 1.86 0 01-1.86-1.86v-18.933a1.86 1.86 0 011.86-1.861m0 27.242h21.251a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.251a1.86 1.86 0 01-1.86-1.86v-18.934a1.86 1.86 0 011.86-1.86m29.642-54.484h21.251a1.86 1.86 0 011.86 1.86v18.934a1.86 1.86 0 01-1.86 1.86h-21.25a1.86 1.86 0 01-1.86-1.86v-18.934a1.86 1.86 0 011.86-1.86" />
    </g>
  </svg>
)
