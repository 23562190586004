import React from 'react'
import styled from 'styled-components'
import { rem, grid, minWidth } from 'styled-tidy'
import theme from 'lib/styles/theme'
import projects from 'lib/projects'
import ProjectGridTile from 'components/project-grid-tile'
import Arrow from 'components/icons/arrow'

const { silver } = theme.colors

const Wrapper = styled.div`
  margin: 0 auto;

  > div {
    ${grid(2, 0)}

    :nth-child(even) {
      direction: rtl;

      > * {
        direction: ltr;
      }
    }
  }

  > p {
    margin: 0 0 ${rem(24)};
  }

  ${minWidth(1232)`
    max-width: ${rem(1168)};
  `}
`

export default () => {
  return (
    <Wrapper>
      <p aria-hidden>
        <Arrow fill={silver} size={24} rotate={90} />
      </p>
      {projects.map(project => (
        <ProjectGridTile key={project.path} project={project} />
      ))}
    </Wrapper>
  )
}
