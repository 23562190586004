import React from 'react'
import styled from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Babel from 'components/logos/babel'
import Docker from 'components/logos/docker'
import Mobx from 'components/logos/mobx'
import Nextjs from 'components/logos/nextjs'
import Reactjs from 'components/logos/reactjs'
import Webpack from 'components/logos/webpack'

const { bone, tundra } = theme.colors

const Wrapper = styled.div`
  border-top: ${rem(1)} solid ${bone};
  padding: ${rem(24)} 0;
`

const Grid = styled.p`
  align-items: center;
  display: grid;
  grid-row-gap: ${rem(16)};
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  margin: 0 ${rem(32)};

  ${media.xsmall`
    grid-row-gap: ${rem(32)};
  `}

  ${media.small`
    display: inline-grid;
    grid-gap: ${rem(32)};
    grid-template-columns: repeat(${({ children }) => children.length}, auto);
    margin: 0 auto;
  `}
`

export default () => {
  return (
    <Wrapper>
      <Grid>
        <Reactjs fill={tundra} aria-label="React" />
        <Nextjs fill={tundra} aria-label="Next.js" />
        <Mobx fill={tundra} aria-label="MobX" />
        <Webpack fill={tundra} aria-label="Webpack" />
        <Babel fill={tundra} height={36} aria-label="Babel" />
        <Docker fill={tundra} aria-label="Docker" />
      </Grid>
    </Wrapper>
  )
}
