import React from 'react'
import styled from 'styled-components'
import { rem, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import Layout, { Main } from 'layouts/default'
import BalanceText from 'react-balance-text'
import Head from 'components/head'
import ExternalLink from 'components/external-link'
import TechGrid from 'components/tech-grid'
import ProjectGrid from 'components/project-grid'

const { white, black } = theme.colors

const Title = styled.h1`
  @keyframes textIn {
    0%,
    10% {
      color: ${white};
    }
    100% {
      color: ${black};
    }
  }
  animation: textIn 2s ease;
  margin: 0 0 ${rem(16)};
  position: relative;

  &,
  * {
    line-height: 1.1;
  }

  @keyframes open {
    0%,
    10% {
      width: 100%;
      transform: skew(45deg);
    }
    0%,
    10%,
    99% {
      box-shadow: 0 0 ${rem(240)} ${white};
    }
    100% {
      box-shadow: none;
      transform: none;
      width: 0;
    }
  }

  :before,
  :after {
    ${position('absolute', 0, 'auto')}
    animation: open 2.5s ease;
    background: ${white};
    content: '';
    overflow: hidden;
    width: 0;
  }

  :before {
    left: -50%;
  }

  :after {
    right: -50%;
  }
`

const Intro = styled.p`
  margin: 0 auto;
  max-width: ${rem(600)};
`

export default () => (
  <Layout>
    <Head />
    <Main>
      <Title>
        <BalanceText>
          Hello. I'm Doug. I make web things &amp; empower people.
        </BalanceText>
      </Title>
      <Intro>
        <BalanceText>
          Currently leading the front-end dev team at{' '}
          <ExternalLink href="https://www.digitalmotors.com/">
            Digital Motors
          </ExternalLink>{' '}
          , an exciting startup in the automotive space. I do occasional
          freelance design and development projects as I find time.
        </BalanceText>
      </Intro>
    </Main>
    <TechGrid />
    <ProjectGrid />
  </Layout>
)
