import React from 'react'
import theme from 'lib/styles/theme'

const baseWidth = 295
const baseHeight = 178
const ratio = baseWidth / baseHeight

export default ({ height = 48, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    height={`${height}px`}
    width={`${Math.ceil(height * ratio)}px`}
    viewBox={`0 0 ${baseWidth} ${baseHeight}`}
    fill={fill}
    {...rest}
  >
    <path d="M69.749 46.729h55.52v4.413H74.839v33.213h47.424v4.413H74.838v36.464h51.01v4.413h-56.1V46.73zm60.495 0h5.899l26.14 36.465 26.72-36.465L225.348.411l-59.709 86.615 30.768 42.62h-6.13l-27.992-38.788-28.108 38.787h-6.015l31-42.62-28.917-40.296zm68.36 4.413v-4.413h63.271v4.413h-29.149v78.503h-5.089V51.142h-29.033zM.29 46.729H6.65l87.726 131.273-36.253-48.357L5.61 53l-.231 76.645H.289zM261.362 123.9c1.053 0 1.82-.805 1.82-1.846s-.767-1.847-1.82-1.847c-1.04 0-1.82.806-1.82 1.847 0 1.04.78 1.847 1.82 1.847zm5.001-4.858c0 3.074 2.216 5.082 5.447 5.082 3.441 0 5.52-2.07 5.52-5.665v-12.655h-2.772v12.643c0 1.995-1.003 3.061-2.773 3.061-1.584 0-2.661-.991-2.698-2.466h-2.724zm14.595-.161c.198 3.197 2.86 5.243 6.82 5.243 4.234 0 6.883-2.145 6.883-5.566 0-2.69-1.51-4.177-5.174-5.032l-1.968-.483c-2.328-.546-3.268-1.277-3.268-2.554 0-1.611 1.46-2.665 3.651-2.665 2.08 0 3.516 1.03 3.776 2.678h2.698c-.16-3.012-2.81-5.132-6.437-5.132-3.899 0-6.498 2.12-6.498 5.305 0 2.628 1.473 4.19 4.703 4.946l2.303.558c2.364.557 3.404 1.375 3.404 2.739 0 1.586-1.634 2.74-3.862 2.74-2.39 0-4.048-1.08-4.283-2.777h-2.748z" />
  </svg>
)
